import { motion } from 'framer-motion'
import React from 'react'
import { Body } from '#app/components/typography'
import { Icon } from '#app/components/ui/icon'
import { cn } from '#app/utils/misc'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	error:
		| {
				message?: string
		  }
		| undefined
	isValid?: boolean
}

// This input was created to be used with react-hook-form

export const InputField = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, placeholder, isValid, error, ...props }, ref) => {
		//Added id or the input field would not react when you clicked the placeholder
		const inputId = props.id || props.name

		return (
			<div className={cn('space-y-2', className)}>
				<div className="relative">
					<input
						id={inputId}
						type={type}
						placeholder=" "
						className={cn(
							'peer z-20 h-fit  w-full appearance-none rounded-lg border border-beige-90 border-input bg-white px-6 py-4 ring-offset-background placeholder:text-muted-foreground placeholder:opacity-20 focus:outline focus:outline-1 focus:outline-black-90 disabled:cursor-not-allowed disabled:opacity-50',
							error && 'border-red text-red focus:outline-red',
						)}
						ref={ref}
						{...props}
					/>
					<label
						htmlFor={props.name}
						className="absolute start-6 top-0 z-30 origin-[0] scale-75 select-none bg-transparent text-sm text-gray-500 transition-all duration-200 
					peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100
					peer-focus:top-2 peer-focus:-translate-y-2 peer-focus:scale-75 
					peer-focus:text-slate-700 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-2 "
					>
						{placeholder}
					</label>
					<motion.div
						className="text-mint-green-60 opacity-0"
						animate={{ opacity: isValid ? 1 : 0 }}
					>
						<Icon name="check" size="lg" className="absolute right-6 top-4" />
					</motion.div>
				</div>
				<Body
					className="bg-transparent text-xs text-red"
					body={error?.message?.toString()}
				/>
			</div>
		)
	},
)
InputField.displayName = 'Input'
